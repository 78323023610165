import React from 'react';
import { graphql } from 'gatsby';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import MEDIA_QUERY from '../../constants/media-queries';
import Layout from '../../components/Layout';


const AboutWrap = styled('div')`
  display: flex;

  ${MEDIA_QUERY.M} {
    padding: 2.5em;
  }
`;


const AboutContent = styled('div')`
  font-size: 0.95em;
  
  max-width: 100%;
  margin: 0 auto;

  img {
    min-width: 150px;
    width: 25%;
  }

  ${MEDIA_QUERY.M} {
    max-width: 80%;
    font-size: 1.15em;
  }
`;

const AboutPage = ({ data, location }) => {
  const { markdownRemark } = data;

  return (
    <Layout location={location}>
      <AboutWrap>
        <AboutContent 
          dangerouslySetInnerHTML={{ __html: markdownRemark.html }} 
        />
      </AboutWrap>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { name: { eq: "about" } }) {
      html
    }
  }
`;
